<template>
  <div class="completebox">
    <div class="dp-f jc-c mb-24">
      <div class="imgbox w-52 h-52 pt-r">
        <div class="pt-a w-52 h-52 dp-fc time" v-if="state.authenticationState == 6">{{ state.time }}</div>
        <img src="@/assets/img/realname/time.png" class="w-52 h-52" v-if="state.authenticationState == 6">
        <img src="@/assets/img/realname/error.png" class="w-52 h-52" v-else-if="state.authenticationState == 7">
        <img src="@/assets/img/realname/success.png" class="w-52 h-52" v-else-if="state.authenticationState == 8">
      </div>
    </div>
    <!-- 等待认证 -->
    <div class="ta-c mb-10" v-if="state.authenticationState == 6">等待认证</div>
    <div class="label ta-c mb-100" v-if="state.authenticationState == 6">认证信息已提交，请耐心等待…</div>
    <div class="ta-c mb-100" v-else-if="state.authenticationState == 7">实名认证未通过！</div>
    <div class="ta-c mb-100" v-else-if="state.authenticationState == 8">实名认证已通过！</div>
    <oabutton v-model:loading="state.loading" v-if="state.authenticationState == 7" class="m-0-a mb-30" width='462' height='48' title="重新发起验证" CSStype=2
      style="border-radius: 4px;font-size: 18px;" @buttonclick="Reinitiate()"/>
    <oabutton v-else class="m-0-a mb-30" width='462' height='48' title="返回首页" CSStype=2
      style="border-radius: 4px;font-size: 18px;" @buttonclick="router_push_name('operation_worktable')"/>
  </div>
</template>
<script setup>
import { reactive, defineEmits, onUnmounted, onMounted, computed } from "vue";
import { router_push_name } from "@/utils/server/router";
import { useStore } from "vuex";
import { selectById } from "@/utils/server/getdata.js"
import { httpToken } from "@/utils/request";
import {resetUserInfo} from "@/api/user"
import { configu } from '@/utils/config'//引入公用文件
import qs from "qs";
// 5对公账户验证完成 6：等待资料提交7：资料提交失败8认证完成9：已撤销 10认证失败
const emit = defineEmits(['setStep'])
const store = useStore();
const state = reactive({
  loading: false,//loading 显示
  authenticationState: computed(() => {
    return store.state.realName.companyInfo.authenticationState
  }),
  code: 1,//1等待提交 2实名认证通过 3实名认证未通过
  time: 30,//倒记时
  setIntervalObj: {},//定时器对象
});
// 重新发起验证
const Reinitiate = () => {
  state.loading=true
  httpToken({
    method: "post",
    url: '/admin/certification/updateStatus',
    data: qs.stringify({
      status: 1,
      companyId: store.state.realName.companyId,
      mode: configu.mode
    })
  }).then((res) => {
    selectById(res.data,2).then(res2 => {
			state.loading = false
			emit('setStep',1)
		}).catch(() => {
			state.loading = false
		})
  }).catch(() => {
		state.loading = false
	})
}
// 执行循环定时器
const use_setInterval = (() => {
  state.setIntervalObj = setInterval(() => {
    state.time--
    if (state.time == 0) {
      state.wait = false
      clearInterval()
    }
  }, 1000)
})
// 清除定时器
const clearInterval = (() => {
  window.clearInterval(state.setIntervalObj);
})
onUnmounted(() => {
  clearInterval()
})
// 完成最后认证
const setEnterpriseFive = () => {
  let realId=store.state.realName.companyInfo.id
  httpToken({
    method: "post",
    url: '/admin/certification/enterpriseFive',
    data: {
      companyId: store.state.realName.companyId,
    }
  }).then((res) => {
    resetUserInfo(()=>{})
    selectById(realId,2)
  }).catch(()=>{
    selectById(realId,2)
  })
}
onMounted(() => {
  if (state.authenticationState == 6) {
    use_setInterval()
    setEnterpriseFive()
  }
})

</script>
<style lang="scss" scoped >
.completebox {
  padding-top: 104px;
}

.time {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}

.label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.mb-100 {
  margin-bottom: 100px;
}</style>
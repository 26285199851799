<template>
  <div class="pt-r box">
    <div class="bgbox pt-a"></div>
    <navigation title="企业实名认证" class="ritem"></navigation>
    <div class="w100 pt-40 pr-30 pl-30 ritem">
      <steps :index="state.index" />
      <information v-if="state.index == 1" @setStep="setStep" />
      <authorization v-if="state.index == 2" @setStep="setStep" />
      <oletter v-if="state.index == 3" @setStep="setStep" />
      <complete v-if="state.index == 4" @setStep="setStep" />
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import { useRoute } from "vue-router";
import navigation from "./components/navigation.vue";//导航条
import steps from "./components/enterprise/steps.vue";//步骤条
import information from "./components/enterprise/information.vue";//填写资料
import authorization from "./components/enterprise/authorization.vue";//选择认证方式
import oletter from "./components/enterprise/oletter.vue"//上传资料
import complete from "./components/enterprise/complete.vue";//完成认证
const route = useRoute()
const state = reactive({
  index: 1,//当前环节下标
});
// 重新设置进度
const setStep = (ind) => {
  state.index = ind
}
if (route.params.stepIndex) {
  setStep(route.params.stepIndex)
}
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

.box {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.bgbox {
  width: 100%;
  height: 715px;
  background: linear-gradient(180deg, rgba(238, 199, 116, 0.09) 0%, rgba(238, 199, 116, 0) 100%);
  z-index: 1;
}

.ritem {
  position: relative;
  z-index: 2;
}</style>